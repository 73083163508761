<template>
  <BaseModal
    size="3xl"
    :full-screen="fullScreenModal"
    :show="show"
    :title="title"
    @close="$emit('close')"
  >
    <PromptForm
      :initial-values="initialValues"
      @create="(data) => $emit('create', data)"
      @update="(data) => $emit('update', data)"
    />
  </BaseModal>
</template>

<script setup lang="ts">
import useFullScreenModal from '~/composables/useFullScreenModal'

defineEmits(['close', 'create', 'update'])

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  initialValues: {
    type: Object,
    default: () => ({
      id: null,
      title: '',
      content: '',
      category: 'chatgpt',
    }),
  },
})

const title = computed(() =>
  props.initialValues.id ? 'Edit Prompt' : 'New Prompt'
)

const fullScreenModal = useFullScreenModal()
</script>
