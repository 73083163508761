<template>
  <template v-if="authenticated">
    <NuxtLoadingIndicator :height="4" color="#6366f1" />
    <div class="flex flex-row h-screen">
      <AppSidebar />
      <PromptSidebar :sidebar-open="sidebarOpen" @close="sidebarOpen = false" />
      <div class="flex grow flex-col">
        <div class="flex flex-none bg-red-200">
          <TheHeader
            :term="route.query.term as string || ''"
            @toggle-sidebar="sidebarOpen = !sidebarOpen"
            @search="handleSearch"
          />
        </div>
        <slot />
      </div>
    </div>
    <FolderDeleteModal
      :show="showFolderDeleteModal"
      :folder="folder"
      @close="showFolderDeleteModal = false"
      @delete="handleFolderDelete"
    />
    <FolderFormModal
      :show="showFolderModal"
      :initial-values="folder"
      @close="showFolderModal = false"
      @update="showFolderModal = false"
    />
    <FolderShareModal
      :initial-values="folder"
      :show="showFolderShareModal"
      @close="showFolderShareModal = false"
    />
    <PromptFormModal
      :initial-values="prompt"
      :show="showPromptModal"
      @close="showPromptModal = false"
      @create="handlePromptCreate"
      @update="handlePromptUpdate"
    />
    <PromptCopyModal
      :initial-values="prompt"
      :show="showPromptCopyModal"
      @close="showPromptCopyModal = false"
    />
    <PromptImportCsvModal
      :show="showPromptImportCsvModal"
      @close="showPromptImportCsvModal = false"
      @imported="handlePromptImport"
    />
    <PromptShareModal
      :initial-values="prompt"
      :show="showPromptShareModal"
      @close="showPromptShareModal = false"
    />
  </template>
  <BaseToast />
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import useEventBus, { EVENTS } from '~/composables/useEventBus'
import useApiFetch from '~/composables/useApiFetch'
import useResponsive from '~/composables/useResponsive'
import usePromptStore from '~/stores/usePromptStore'
import useFolderStore from '~/stores/useFolderStore'
import useCurrentUser from '~/composables/useCurrentUser'
import { useClipboard } from '@vueuse/core'
import useNotifier from '~/composables/useNotifier'
import useAuthStore from '~/stores/useAuthStore'
import useAnalytics from '~/composables/useAnalytics'

const { isLargeScreen } = useResponsive()
const promptStore = usePromptStore()
const authStore = useAuthStore()
const prompt = ref({})
const sidebarOpen = ref(false)
const router = useRouter()
const pending = ref(true)
const route = useRoute()
const { authorized } = useCurrentUser()
const { notifySuccess } = useNotifier()
const analytics = useAnalytics()
const { user, authenticated } = storeToRefs(authStore)

// ------------------------------------------------------
// ANALYTICS
// ------------------------------------------------------

const analyticsBus = useEventBus({ name: EVENTS.TRACK })

analyticsBus.on(({ event, properties }) => {
  analytics.track({ event, properties })
})

// ------------------------------------------------------
// SEARCH
// ------------------------------------------------------

const searchBus = useEventBus({ name: EVENTS.SEARCH })

const handleSearch = ({ term }: { term: string }) => {
  router.push(`/prompts/folders/search?term=${encodeURIComponent(term || '')}`)
}

searchBus.on(({ term }) => {
  sidebarOpen.value = false
  handleSearch({ term })
})

// ------------------------------------------------------
// FOLDERS
// ------------------------------------------------------

const folder = ref({})
const folderStore = useFolderStore()
const createFolderBus = useEventBus({ name: EVENTS.CREATE_FOLDER })
const deleteFolderBus = useEventBus({ name: EVENTS.DELETE_FOLDER })
const editFolderBus = useEventBus({ name: EVENTS.EDIT_FOLDER })
const { folders } = storeToRefs(folderStore)
const shareFolderBus = useEventBus({ name: EVENTS.SHARE_FOLDER })
const showFolderDeleteModal = ref(false)
const showFolderModal = ref(false)
const showFolderShareModal = ref(false)

const fetchFolders = async () => {
  await folderStore.fetch({ query: 'folder_type=prompt' })
}

const handleFolderDelete = () => {
  promptStore.fetchPromptTags()

  if (folders.value.data.length === 0) {
    router.push('/prompts/home')
  } else {
    router.push(`/prompts/folders/${folders.value.data[0].id}`)
  }
}

createFolderBus.on(({ folder }) => {
  router.push(`/prompts/folders/${folder.id}`)
})

shareFolderBus.on((event) => {
  showFolderShareModal.value = true
  folder.value = event.folder
})

deleteFolderBus.on((event) => {
  showFolderDeleteModal.value = true
  folder.value = event.folder
})

editFolderBus.on((event) => {
  showFolderModal.value = true
  sidebarOpen.value = false
  folder.value = event.folder
})

// ------------------------------------------------------
// PROMPT IMPORTS
// ------------------------------------------------------

const handlePromptImport = () => {
  fetchPrompts()
  fetchPromptTags()
  fetchFolders()

  showPromptImportCsvModal.value = false
}

const handlePromptImportShow = () => {
  showPromptImportCsvModal.value = true
}

useEventBus({ name: EVENTS.IMPORT_PROMPTS }).on(handlePromptImportShow)

// ------------------------------------------------------
// PROMPTS
// ------------------------------------------------------

const { copy, text } = useClipboard()
const copyPromptBus = useEventBus({ name: EVENTS.COPY_PROMPT })
const deletePromptBus = useEventBus({ name: EVENTS.DELETE_PROMPT })
const editPromptBus = useEventBus({ name: EVENTS.EDIT_PROMPT })
const newPromptBus = useEventBus({ name: EVENTS.NEW_PROMPT })
const selectPromptBus = useEventBus({ name: EVENTS.SELECT_PROMPT })
const sharePromptBus = useEventBus({ name: EVENTS.SHARE_PROMPT })
const showPromptModal = ref(false)
const showPromptCopyModal = ref(false)
const showPromptImportCsvModal = ref(false)
const showPromptShareModal = ref(false)

const fetchPrompts = async () => {
  await promptStore.fetchPrompts()
}

const fetchPromptTags = async () => {
  await promptStore.fetchPromptTags()
}

const handlePromptCreate = (options: any = {}) => {
  showPromptModal.value = false
  analytics.track({ event: EVENTS.CREATE_PROMPT })
  promptStore.addPrompt({ prompt: options.prompt })
}

const handlePromptCopy = (event: any) => {
  if ((event.prompt.content || '').search('{{') !== -1) {
    showPromptCopyModal.value = true
    prompt.value = event.prompt
    return
  }

  copy(event.prompt.content)
  analytics.track({ event: EVENTS.COPY_PROMPT })
  notifySuccess({ title: 'Copied!', text: 'Prompt copied to clipboard.' })
}

const handlePromptSelect = (event: any) => {
  if (isLargeScreen.value) {
    let url = `/prompts/folders/${route.params.id}/prompts/${event.prompt.id}`

    if (route.query.term) {
      url += `?term=${encodeURIComponent(route.query.term as string)}`
    }

    router.push(url)
    return
  }

  showPromptModal.value = true
  prompt.value = { ...event.prompt }
}

const handlePromptUpdate = (options: any = {}) => {
  showPromptModal.value = false
  promptStore.updatePrompt({ prompt: options.prompt })
}

copyPromptBus.on(handlePromptCopy)

sharePromptBus.on((event) => {
  showPromptShareModal.value = true
  prompt.value = { ...event.prompt }
})

deletePromptBus.on(({ prompt }) => {
  // move to parent folder if we're on a prompt page
  if (route.params.prompt_id) {
    router.push(`/prompts/folders/${route.params.id}`)
  }

  promptStore.removePrompt({ promptId: prompt.id })

  useApiFetch(`/prompts/${prompt.id}`, {
    method: 'DELETE',
  })
})

editPromptBus.on(handlePromptSelect)

selectPromptBus.on(handlePromptSelect)

newPromptBus.on((event) => {
  showPromptModal.value = true

  const params = { ...event.prompt }

  if (Number(route.params.id) > 0) {
    params.folder_id = Number(route.params.id)
  }

  prompt.value = params
})

onMounted(async () => {
  pending.value = true

  await authStore.fetch()
  await fetchFolders()
  await fetchPrompts()
  await fetchPromptTags()

  analytics.init()
  analytics.identify({ id: user.value.id })

  pending.value = false

  if (route.query.triggerNew) {
    newPromptBus.emit({ prompt: { content: route.query.content } })
  }

  if (route.query.import) {
    handlePromptImportShow()
  }

  if (user.value.id && !user.value.emailVerified) {
    router.push(`/users/${user.value?.id}/verify-email`)
  }
})
</script>
