<template>
  <BaseModal
    size="6xl"
    title="Import Prompts from CSV"
    :show="show"
    :body-padding="false"
    @close="$emit('close')"
  >
    <div v-if="!file" class="p-6 prose">
      <p>
        If you already have a list of prompts, you can import those to your
        PromptDrive.ai account via CSV.
      </p>
      <p>
        <a href="https://www.youtube.com/watch?v=9lssHEwj_1A" target="_BLANK">
          Watch the video
        </a>
        for a quick walkthrough on how to organize your CSV to ensure your
        import is successful.
      </p>
      <p>
        We recommend
        <a href="/prompt-import-template.csv">
          downloading a copy of our CSV Import Template
        </a>
        to help you.
      </p>
      <div class="border rounded bg-gray-50 border-gray-100 p-6">
        <input type="file" accept=".csv" @change="handleFileUpload" />
      </div>
    </div>
    <div v-if="errors.length > 0" class="px-6 py-4 pb-0">
      <BaseErrorAlert :errors="errors" />
    </div>
    <div class="bg-white" v-if="data && data.meta">
      <div class="flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <div class="px-6 pt-4">
              Please match each column from your spreadsheet below with a field
              from our database.
            </div>
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    class="relative isolate px-6 py-4 text-left text-sm"
                    v-for="field in data.meta.fields"
                  >
                    <select
                      class="form-select block w-full font-normal pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      @change="(event: any) => handleMappingChange({ field, event })"
                    >
                      <option
                        :value="option.value"
                        v-for="option in options"
                        :disabled="option.disabled"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr v-for="row in preview">
                  <td
                    class="w-48 px-6 py-4 text-sm text-gray-500 align-top"
                    v-for="field in data.meta.fields"
                  >
                    {{ row[field] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-100 p-4 flex items-end">
      <div class="flex-1"></div>
      <BaseButton :loading="importing" @click="handleImport">Import</BaseButton>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import Papa from 'papaparse'
import useApiFetch from '~/composables/useApiFetch'
import useNotifier from '~/composables/useNotifier'

const emit = defineEmits(['close', 'imported'])

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
})

const { notifySuccess } = useNotifier()
const file = ref<File | null>(null)
const data = ref<any>([])
const errors = ref<any>([])
const preview = ref<any>([])
const importing = ref(false)
const mapping = ref<any>({})
const options = [
  {
    value: null,
    label: 'Select a field...',
  },
  {
    value: 'title',
    label: 'Title',
  },
  {
    value: 'content',
    label: 'Content',
  },
  {
    value: 'platform',
    label: 'Platform',
  },
  {
    value: 'folder',
    label: 'Folder',
  },
  {
    value: 'tags',
    label: 'Tags',
  },
  {
    value: 'notes',
    label: 'Notes',
  },
  {
    value: 'ignore',
    label: 'Ignore this column',
    disabled: true,
  },
]

options.map((option: any) => {
  if (option.value === '') return
  mapping.value[option.value] = null
})

const isMatched = (field: string) => {
  return mapping.value[field] !== null
}

const parseFile = async (file: File | null) => {
  return new Promise((resolve) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results: any) => {
        resolve(results)
      },
    })
  })
}

const handleMappingChange = ({ event, field }: any) => {
  errors.value = []
  mapping.value[event.target.value] = field
}

const handleFileUpload = async (event: any) => {
  file.value = event.target.files[0]
  data.value = await parseFile(file.value)
  preview.value = data.value.data.slice(0, 2)
}

const handleImport = async () => {
  if (!mapping.value.title) {
    errors.value.push('Title is required')
  }
  if (!mapping.value.content) {
    errors.value.push('Content is required')
  }
  if (errors.value.length > 0) {
    return
  }

  importing.value = true

  const response = await useApiFetch('/prompts/import', {
    method: 'POST',
    body: {
      data: data.value.data,
      mapping: mapping.value,
    },
  })

  importing.value = false
  emit('imported')

  notifySuccess({
    title: 'Success',
    text: 'Your prompts have been imported.',
  })
}
</script>
