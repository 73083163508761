import { useMediaQuery } from '@vueuse/core'

const useResponsive = () => {
  const isMobile = useMediaQuery('(max-width: 640px)')
  const isTablet = useMediaQuery('(max-width: 768px)')
  const isMediumScreen = useMediaQuery('(max-width: 900px)')
  const isLargeScreen = useMediaQuery('(min-width: 1024px)')
  const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const isLightMode = useMediaQuery('(prefers-color-scheme: light)')

  return {
    isMobile,
    isMediumScreen,
    isTablet,
    isLargeScreen,
    isDarkMode,
    isLightMode,
  }
}

export default useResponsive
