<template>
  <BaseModal size="xl" :show="show" :title="title" @close="$emit('close')">
    <FormKit v-model="form" type="form" :actions="false" @submit="handleSubmit">
      <div
        class="grid text-gray-700"
        v-if="folder && folder.visibility === 'public'"
      >
        This prompt is already shared at the folder level. Please edit its
        visibility there.
      </div>
      <div
        class="grid gap-y-5"
        v-if="!folder || (folder && folder.visibility !== 'public')"
      >
        <RadioGroup v-model="form.visibility">
          <RadioGroupLabel>
            <div class="mb-4">
              Who do you want to share {{ form.title }} with?
            </div>
          </RadioGroupLabel>
          <div class="grid gap-4">
            <RadioGroupOption v-slot="{ checked }" value="team">
              <div
                class="flex rounded items-center border p-4"
                :class="
                  checked
                    ? 'bg-gray-50 border border-gray-200'
                    : 'border-gray-100'
                "
              >
                <BaseIcon
                  class="w-8 h-8 mr-4"
                  :class="
                    checked
                      ? 'opacity-100 text-green-400'
                      : 'opacity-20 text-gray-400'
                  "
                  name="heroicons:check-circle-solid"
                />
                <div class="flex-1">
                  <div class="font-semibold">Team Only</div>
                  <div class="text-xs">
                    Only team members of PromptDrive can view this prompt
                  </div>
                </div>
              </div>
            </RadioGroupOption>
            <RadioGroupOption v-slot="{ checked }" value="public">
              <div
                class="flex rounded items-center border p-4"
                :class="
                  checked ? 'bg-gray-50 border-gray-200' : 'border-gray-100'
                "
              >
                <BaseIcon
                  class="w-8 h-8 mr-4"
                  :class="
                    checked
                      ? 'opacity-100 text-green-400'
                      : 'opacity-20 text-gray-400'
                  "
                  name="heroicons:check-circle-solid"
                />
                <div class="flex-1">
                  <div class="font-semibold">Public</div>
                  <div class="text-xs">
                    Anyone with the link can view this prompt.
                  </div>
                  <FormKit
                    type="text"
                    wrapper-class="mt-2 relative"
                    :value="form.url"
                  >
                    <template #suffix>
                      <div class="absolute right-[5px] top-[5px]">
                        <BaseButton size="xs" @click="handleCopy">
                          Copy
                        </BaseButton>
                      </div>
                    </template>
                  </FormKit>
                </div>
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </div>
    </FormKit>
  </BaseModal>
</template>

<script setup lang="ts">
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import useNotifier from '~/composables/useNotifier'
import useApiFetch from '~/composables/useApiFetch'
import usePromptStore from '~/stores/usePromptStore'
import useFolderStore from '~/stores/useFolderStore'
import { useClipboard } from '@vueuse/core'
import useConfig from '~/composables/useConfig'
import useFullScreenModal from '~/composables/useFullScreenModal'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  initialValues: {
    type: Object,
    default: () => ({
      id: null,
      title: '',
      content: '',
      category: 'chatgpt',
    }),
  },
})

const emit = defineEmits(['close', 'save'])
const folderStore = useFolderStore()
const promptStore = usePromptStore()
const title = computed(() => `Share "${form.value.title}"`)
const form = ref<any>({})
const submitting = ref(false)
const { notifySuccess } = useNotifier()
const plan = ref('startup')
const { copy } = useClipboard()
const config = useConfig()
const folder = ref({})
const fullScreenModal = useFullScreenModal()

watch(
  () => props.initialValues,
  () => {
    form.value = { ...props.initialValues }
    form.value.url =
      config.public.APP_URL + '/shared/prompt-' + form.value.permalink
    folder.value = folderStore.getFolderById(form.value.folder_id)
  }
)

watch(
  () => form.value.visibility,
  () => {
    handleSubmit()
  }
)

const handleSubmit = async () => {
  const response = await useApiFetch(`/prompts/${form.value.id}`, {
    method: 'PUT',
    body: { visibility: form.value.visibility },
  })

  promptStore.updatePrompt({ prompt: response.data.value })
}

const handleCopy = () => {
  copy(form.value.url)
  notifySuccess({ title: 'Copied!', text: 'Prompt copied to clipboard.' })
}
</script>
